<template>
    <div
    >
        <CSDialog
                dialogTitle="线下付款"
                dialogWidth="960px"
                :dialogVisible="modalShowStatus"
                @onClose="changeModalShowStatus(false)"
                @onConfirm="submitPayResult"
                dialogConfirmBtnText="确定"
        >
            <div
                    slot="dialog-content"
                    style="padding: 20px 30px;"
                    class="offline-pay-modal-content"
            >
                <!-- 上传文件使用（不可删除） -->
                <input type="file" id="upload-file" style="display: none"/>
                <div class="form-item">
                    <label class="hy-label">支付渠道</label>
                    <div class="form-item-content">
                        <label
                                v-for="(channelName, channel) in payChannelMapping"
                                :key="channel"
                        >
                            <input
                                    v-model="payChannel"
                                    type="radio"
                                    :value="channel"
                            />
                            {{ channelName }}
                        </label>
                    </div>
                </div>
                <div class="form-item">
                    <label class="hy-label">已付金额</label>
                    <div class="form-item-content">
                        <input
                                type="number"
                                v-model="actuallyMoney"
                                class="cs-input mr-1"
                                style="height: 40px; border-radius: 4px; border: 1px solid #999;"
                        />元
                    </div>
                </div>
                <div class="form-item">
                    <label class="hy-label">付款凭证</label>
                    <div class="form-item-content">
                        <div>
                            <div
                                    v-for="(src, imgIndex) in voucher"
                                    :key="src"
                                    class="photo-view"
                            >
                                <img :src="src"/>
                                <i
                                        class="icon-close"
                                        @click="deletePhotos(imgIndex)"
                                ></i>
                            </div>
                            <div
                                    class="upload-photo"
                                    @click="uploadArchive"
                                    v-if="voucher.length < 5"
                            >
                                <div class="text-center">
                                    <img
                                            src="../../assets/upload.png"
                                            alt=""
                                            style="margin: 5px auto"
                                    />
                                    <p style="color: #999;">上传照片</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-item">
                    <label class="hy-label hy-remarks">备注</label>
                    <div class="form-item-content">
                        <textarea
                                class="cs-input"
                                v-model="comment"
                                style="width: 700px; height: 200px; padding-left: 10px; resize: none;"
                                maxlength="50"
                                placeholder="限50个字"
                        ></textarea>
                    </div>
                </div>
            </div>
        </CSDialog>
    </div>
</template>
<script>
    import {ticketOfflinePaymentUrl} from "@/requestUrl";
    import {PAY_CHANNEL} from "../../../common/commonConst";
    import CSDialog from "@/components/common/CSDialog";

    export default {
        name: "OfflinePayTicket",
        props: {
            modalShowStatus: Boolean,
            ticketId: Number,
            payable: Number,
        },
        components: {
            CSDialog
        },
        data() {
            return {
                payChannelMapping: {
                    [PAY_CHANNEL.CASH_PAY]: "现金支付",
                    [PAY_CHANNEL.BANK_PAY]: "银行转帐",
                    [PAY_CHANNEL.ALI_PAY]: "支付宝转账",
                    [PAY_CHANNEL.WE_CHAT_PAY]: "微信转账",
                },
                payChannel: "",
                actuallyMoney: "",
                voucher: [],
                comment: "",
            };
        },
        mounted() {
        },
        methods: {
            deletePhotos(index) {
                this.voucher.splice(index, 1);
            },
            /**
             * 上传凭证
             *  */
            uploadArchive() {
                const el = document.querySelector("#upload-file");
                el.addEventListener(
                    "change",
                    async (e) => {
                        this.voucher.push(await this.choosePhoto(e));
                    },
                    {once: true}
                );
                el.click();
            },
            /**
             * 上传图片
             * @param {String} base64 base64格式
             *  */
            async uploadPhoto(base64) {
                return new Promise((reslove, reject) => {
                    this.$vc.http.post(
                        "uploadImg",
                        "upload",
                        {
                            img: base64,
                        },
                        {
                            headres: {
                                "Content-Type": "application/json",
                            },
                        },
                        (resStr, res) => {
                            reslove(res.body.fileSaveName);
                        },
                        (errText, err) => {
                            reject(errText);
                        }
                    );
                });
            },
            /**
             * 选择图片
             * @param {HTMLEvent} event
             *  */
            choosePhoto(event) {
                return new Promise(  (resolve) => {
                    var photoFiles = event.target.files;
                    if (photoFiles && photoFiles.length > 0) {
                        // 获取目前上传的文件
                        var file = photoFiles[0]; // 文件大小校验的动作
                        if (file.size > 1024 * 1024 * 2) {
                            this.$vc.toast("图片大小不能超过 2MB!");
                            return false;
                        }
                        // var reader = new FileReader(); //新建FileReader对象
                        // reader.readAsDataURL(file); //读取为base64
                        // reader.onloadend = async (e) => {
                        //     const photo = await this.uploadPhoto(
                        //         reader.result
                        //     ).catch((err) => {
                        //         console.error("上传照片失败");
                        //         return "";
                        //     });
                        //     resolve(photo);
                        // };

                        let formData = new FormData(); //新建FormData对象
                        formData.append("files", photoFiles[0]);
                        formData.append("bucketName", this.Constants.BUCKET_NAMES.IMG.name);
                        formData.append("compress", "true");
                        this.Constants.uploadFileOne(formData).then(photo => {
                            if (photo) {
                                event.target.value = ""
                                resolve(this.Constants.BUCKET_NAMES.IMG.visitUrl + photo);
                            }
                        });

                    }
                });
            },
            // 提交支付结果
            submitPayResult() {
                const {
                    ticketId,
                    payChannel,
                    actuallyMoney,
                    voucher,
                    comment,
                } = this;
                this.$fly
                    .post(ticketOfflinePaymentUrl, {
                        ticketId,
                        payChannel,
                        actuallyMoney: actuallyMoney,
                        voucher: JSON.stringify(
                            voucher
                        ) /* JSON.stringify(JSON.stringify(voucher))
                        .replace('"[', "[")
                        .replace(']"', "]") */,
                        comment,
                    })
                    .then((res) => {
                        this.$emit('offlinewPayTicketBtn', {})
                        this.changeModalShowStatus(false);
                    });
            },
            /**
             * 隐藏/显示弹窗
             * @param {Boolean} isShow 是否显示
             *  */
            changeModalShowStatus(isShow) {
                this.payChannel = "";
                this.actuallyMoney = "";
                this.voucher = [];
                this.comment = "";
                console.log("显示弹窗", isShow);
                this.$emit("change-modal-status", isShow);
            },
        },
    };
</script>

<style lang="stylus" scoped>
    @import './offlinePayModal.styl'
    .form-item {
        display flex
    }

    .form-item .hy-label{
        display inline-block
        width 150px
    }
</style>
